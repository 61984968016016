<template>
  <!-- Prélèvement -->
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Organe</h2>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue
      table
      label="Nom"
      :value="fiche?.organe?.valeur"
    />
  </div>

</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'OrganSubview',
  components: {
    Container,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fiche: {},
    }
  },

  emits: ['set-editable'],

  mounted() {
    this.getFiche()
  },
  methods: {
    async getFiche() {
      this.fiche = await this.taskService.getFiche(this.$route, 'prelevement')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
